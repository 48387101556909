import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'escaperoom-password-website';
  public loginFormData: any = {
    firstname: null,
    password: null
  };
  public formCorrect: boolean;
  public formHasError: boolean;

  public constructor() {
  }

  public submitForm() {
    this.formHasError = false;
    console.log('submit form:')
    console.log('firstname: ', this.loginFormData.firstname)
    console.log('password: ', this.loginFormData.password)

    if (this.loginFormData.firstname == 'Willem' && this.loginFormData.password == "Peter") {
      this.formCorrect = true;
    } else {
      this.formHasError = true;
    }
  }
}
