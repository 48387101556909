<!DOCTYPE html>
<html lang="en-US" class="no-js">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width">
  <title>Australian Notary Services | Document Preparation, Notary and Legalisation Services</title>

  <link rel='dns-prefetch' href='//fonts.googleapis.com'/>
  <link rel='stylesheet' id='cnss_font_awesome_css-css'
        href='https://www.australiannotary.com.au/wp-content/plugins/easy-social-icons/css/font-awesome/css/all.min.css?ver=5.7.2'
        type='text/css' media='all'/>
  <link rel='stylesheet' id='cnss_font_awesome_v4_shims-css'
        href='https://www.australiannotary.com.au/wp-content/plugins/easy-social-icons/css/font-awesome/css/v4-shims.min.css?ver=5.7.2'
        type='text/css' media='all'/>
  <link rel='stylesheet' id='cnss_css-css'
        href='https://www.australiannotary.com.au/wp-content/plugins/easy-social-icons/css/cnss.css?ver=1.0'
        type='text/css' media='all'/>
  <link rel='stylesheet' id='wp-block-library-css'
        href='https://www.australiannotary.com.au/wp-includes/css/dist/block-library/style.min.css?ver=5.6.8'
        type='text/css' media='all'/>
  <link rel='stylesheet' id='contact-form-7-css'
        href='https://www.australiannotary.com.au/wp-content/plugins/contact-form-7/includes/css/styles.css?ver=5.4'
        type='text/css' media='all'/>
  <link rel='stylesheet' id='twentyfifteen-fonts-css'
        href='//fonts.googleapis.com/css?family=Noto+Sans%3A400italic%2C700italic%2C400%2C700%7CNoto+Serif%3A400italic%2C700italic%2C400%2C700%7CInconsolata%3A400%2C700&#038;subset=latin%2Clatin-ext'
        type='text/css' media='all'/>
  <link rel='stylesheet' id='genericons-css'
        href='https://www.australiannotary.com.au/wp-content/themes/ans/genericons/genericons.css?ver=3.2'
        type='text/css' media='all'/>
  <link rel='stylesheet' id='twentyfifteen-style-css'
        href='https://www.australiannotary.com.au/wp-content/themes/ans/style.css?ver=5.6.8' type='text/css'
        media='all'/>
</head>

<body>
<div id="container">
  <div id="wrapper">
    <div id="header">

      <div class="header_top header_ribborn">
        <div class="main">
          <ul>
            <li><a href="tel: 0615838558">(06) 15 83 85 58</a></li>
            <li><a href="mailto:escaperoom@oisterwijkadventures.nl">escaperoom@oisterwijkadventures.nl</a></li>
          </ul>
        </div>
      </div>

      <div class="header_top">
        <div class="main">
          <div class="header_col_lft">
            <a href="#" rel="home" title="Notary Logo"><img
              src="./assets/richman_logo_white.jpeg"
              alt="Australian Notary Services"/></a>
          </div>
          <div class="header_col_rgt">
            <ul>
              <li class="last_enq"><a href="#inline1" class="fancybox" title="Notary"><img
                src="https://www.australiannotary.com.au/wp-content/themes/ans/images/enquiry.png"
                alt="Australian Notary Services"/></a></li>
            </ul>

            <div id="inline1" style="width:400px;display: none;">

              <div role="form" class="wpcf7" id="wpcf7-f52-o1" lang="en-US" dir="ltr">
                <div class="screen-reader-response"><p role="status" aria-live="polite" aria-atomic="true"></p>
                  <ul></ul>
                </div>

              </div>
            </div>
            <div class="clear"></div>
          </div>
          <div id="navigation">
            <div class="main">
              <nav>
                <div class="menu-top-menu-container">
                  <ul id="menu-top-menu" class="menu">
                    <li id="menu-item-190"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-190">
                    </li>
                    <li id="menu-item-14"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-14">
                    </li>
                    <li id="menu-item-519"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-519"><a
                      href="#">Home</a></li>
                    <li id="menu-item-17" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-17">
                      <a rel="noopener" href="#">Ben A. Richman</a></li>
                    <li id="menu-item-16" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-16">
                      <a href="#">Contact Us</a></li>
                  </ul>
                </div>
              </nav>
            </div>
            <div class="clear"></div>
          </div>
          <div class="banner_col">
            <img src="https://www.australiannotary.com.au/wp-content/themes/ans/images/banner.jpg"
                 alt="Australian Notary Services" title="Notary public free service"/>
            <div class="banner_text">
              <div class="main">
                <div class="text_banner">
                  <p>We are committed to providing document preparation, notary and legalisation services in a
                    professional and cost effective manner to our valued clients.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="content">
          <div class="main">
            <div class="content_inner">
              <div class="content_left">
                <div class="left_col">
                </div>
                <div class="right_col">
                  <div id="et-boc" class="et-boc">
                    <div id="et_builder_outer_content" class="et_builder_outer_content">
                      <div class="et-l et-l--post">
                        <div class="et_builder_inner_content et_pb_gutters3">
                          <div class="et_pb_section et_pb_section_0 et_section_regular et_section_transparent">
                            <div class="et_pb_row et_pb_row_0">
                              <div
                                class="et_pb_column et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">
                                <div
                                  class="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_left et_pb_bg_layout_light">
                                  <div class="et_pb_text_inner"><h1>Welcome to Richman Group, Sydney</h1>
                                    <p>Wij hechten waarde aan discretie rondom de financiële transacties van onze
                                      klanten, vandaar dat wij gebruikmaken van een uniek inlogsysteem. Vul hieronder uw
                                      volledige naam en password in, zodat wij uw account kunnen controleren op
                                      echtheid. Let op, dit is hoofdlettergevoelig.</p>
                                    <form #form="ngForm" aria-labelledby="title">
                                      <div class="form-control-group">
                                        <input [(ngModel)]="loginFormData.firstname"
                                               #firstname="ngModel"
                                               name="Volledige naam"
                                               id="input-firstname"
                                               placeholder="Volledige naam"
                                               autofocus
                                               [attr.aria-invalid]="firstname.invalid && firstname.touched ? true : null"
                                               [required]="true"
                                               pattern="Janne Jozef Jansen">
                                      </div>
                                      <div style="padding-top: 10px"></div>
                                      <div class="form-control-group">
                                        <input [(ngModel)]="loginFormData.password"
                                               #password="ngModel"
                                               [required]="true"
                                               name="password"
                                               type="password"
                                               id="input-password"
                                               placeholder="Password"
                                               minlength="6"
                                               [attr.aria-invalid]="password.invalid && password.touched ? true : null"
                                               pattern="HollandseKangoeroe1924">
                                      </div>
                                      <p>Let op, hoofdlettergevoelig!</p>
                                      <div style="padding-top: 20px;">
                                        <button (click)="submitForm()">
                                          Inloggen
                                        </button>
                                      </div>
                                      <div *ngIf="formHasError">
                                        <p style="color: #ce0100">Form data invalid</p>
                                      </div>
                                      <div *ngIf="formCorrect">
                                        <p>🎉🎉🎉 Well done, Familie Jansen! 🎉🎉🎉</p>
                                        <p>Bel of app naar <strong>06-15838558</strong> dat jullie klaar zijn met het
                                          spel en recht hebben op de erfenis van J. Jansen!</p>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="clear"></div>
              </div>
              <div class="content_right">
                <div id="secondary" class="secondary">
                  <div id="widget-area" class="widget-area" role="complementary">
                    <aside id="text-3" class="widget widget_text"><h2>Services on Offer</h2>
                      <div class="textwidget"><p>The Richman Group is een internationaal erkend notarisbureau, dat zich
                        wereldwijd inzet voor haar cliënten. Daarbij sluit zij niemand uit vanwege dubieuze praktijken
                        uit het verleden en worden bovendien ieders vermogen gerespecteerd. Er wordt kundig meegedacht
                        in het veilig stellen en regelen van natalenschap voor derden. Notaris Ben A. Richman is reeds
                        meer dan 20 jaar een allom geroemde professional.</p>
                      </div>
                    </aside>
                    <aside id="text-4" class="widget widget_text"><h2>The Richman Group Services</h2>
                      <div class="textwidget"><p>Mocht u meer informatie willen onze diensten, vraag een vrijblijvend
                        gesprek aan via deze knop <a style="color: #f41600; font-weight: 900;"
                        >CLICK HERE.</a></p>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
        <!-- CONTENT END -->


        <!-- FOOTER START -->
        <div id="footer">
          <div class="main">
            <div class="foot_col_left">
              <div class="foot_nav">
                <div class="menu-footermenu-container">
                  <ul id="menu-footermenu" class="">
                    <li id="menu-item-219"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-219">
                      <a aria-current="page">Home</a></li>
                    <li id="menu-item-220"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-220"><a
                    >Services</a></li>
                    <li id="menu-item-221"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-221"><a
                    >FAQs</a></li>
                    <li id="menu-item-222"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-222"><a
                    >Ben A. Richman</a></li>
                    <li id="menu-item-223"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-223"><a
                    >Testimonials</a></li>
                    <li id="menu-item-523"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-523"><a
                    >Articles</a></li>
                    <li id="menu-item-225"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-225"><a
                    >Contact Us</a></li>
                    <li id="menu-item-226"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-226">
                      <a target="_blank" href="http://www.oisterwijkadventures.nl/escape-room/">ESCAPEROOM OISTERWIJK
                      </a></li>
                  </ul>
                </div>
              </div>
              <p>Liability limited by a scheme approved under professional standards legislation.</p>
              <p>Copyright ©️ 2015-2020 <span>Australian Notary Services</span>. All Rights Reserved.</p>

              <div class="copy">
                <a href="#">Terms of Use</a>
                <a href="#">Privacy Statement</a>
              </div>
              <div class="foot_col_right"></div>
              <div class="clear"></div>

            </div>
            <div class="foot_col_right">
              <div style="float: left; margin-right: 20px;">
                <ul id="" class="cnss-social-icon " style="text-align:right;">
                  <li class="cn-fa-in" style="display:inline-block;"></li>
                  <li class="cn-fa-facebook" style="display:inline-block;"></li>
                  <li class="cn-fa-twitter" style="display:inline-block;"></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
        <!-- FOOTER END -->
      </div>
      <!--WRAPPER END -->
    </div>
    <!--CONTAINER END -->

  </div>
</div>
</body>

</html>
